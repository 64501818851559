<template>
  <!-- old version has layout-padding class -->
  <q-page class="layout-padding">
    <q-tabs v-if="$q.platform.is.mobile" v-model="filter" align="justify" class="text-primary">
      <q-tab name="upcoming" :label="$t('upcoming')" :disable="disabled" />
      <q-tab name="now" :label="$t('active')" :disable="disabled" />
      <q-tab name="past" :label="$t('past')" :disable="disabled" />
    </q-tabs>

    <div v-else>
      <h6 class="col-12 text-uppercase text-faded q-pb-md">
        {{ title }}
      </h6>
    </div>
    <div class="row">
      <m-infinite-scroll
        ref="scroller"
        :load="load"
        :pagination="pagination"
        icon="map"
        resource="journeys"
      >
        <div class="row">
          <div
            v-for="item in filteredItems"
            :key="item.reference"
            class="col-xs-12 col-md-4"
          >
            <router-link :to="`/journeys/${item.reference}`">
              <m-mobile-journey-card
                v-if="$q.platform.is.mobile"
                :label="`#${item.reference}`"
                :date="item.date.date"
                :start-date="item.start_date.date"
                :end-date="item.end_date.date"
                v-bind="item"
              />
              <journey-card
                v-else
                :label="`#${item.reference}`"
                :date="item.date.date"
                v-bind="item"
              />
            </router-link>
          </div>
        </div>
      </m-infinite-scroll>
    </div>
  </q-page>
</template>

<script type="text/javascript">
import { mapGetters } from 'vuex'
import { journeys } from 'api/user'
import journeyCard from './journey-card'
import MMobileJourneyCard from './MMobileJourneyCard'
import { MInfiniteScroll } from 'components/'
import date from 'utils/date-time'
const { getDateDiff } = date

export default {
  name: 'Journeys',
  components: {
    journeyCard,
    MMobileJourneyCard,
    MInfiniteScroll
  },
  data () {
    return {
      items: [],
      pagination: null,
      filter: 'upcoming',
      disabled: false
    }
  },
  computed: {
    ...mapGetters({
      user: 'user',
      journeyFilter: 'entities/journeys/filter',
      sidebarOpen: 'entities/journeys/showSidebar',
      dateFilter: 'entities/journeys/dateFilter',
      typeFilter: 'entities/journeys/typeFilter'
    }),
    title () {
      if (this.journeyFilter === 'upcoming') return this.$t('journey.upcoming')
      if (this.journeyFilter === 'past') return this.$t('journey.previous')
      return this.$t('journey.active')
    },
    filteredItems () {
      const { start, end } = this.dateFilter
      return this.items
        .filter(item => {
          const startDate = item.start_date.date
          const endDate = item.end_date.date
          const beforeStart = start ? getDateDiff(startDate, start).values.milliseconds : 1
          const afterEnd = end ? getDateDiff(end, endDate).values.milliseconds : 1
          return beforeStart >= 0 && afterEnd >= 0
        })
        .filter(item => {
          if (this.typeFilter.length === 0) return true
          return this.typeFilter.includes(item.type)
        })
    }
  },
  watch: {
    journeyFilter (val, old) {
      if (val !== old) {
        this.items = []
        this.$refs.scroller.reset()
      }
    },
    filter (val) {
      this.$store.dispatch('entities/journeys/setFilter', val)
    }
  },
  methods: {
    load (index) {
      if (this.dateFilter.start) {
        const lastItem = this.items[this.items.length - 1]
        const beforeStart = lastItem ? getDateDiff(lastItem.date.date, this.dateFilter.start).values.milliseconds : 1
        if (beforeStart <= 0) return
      }
      this.$store.dispatch('entities/journeys/disableFilters', true)
      var params = { page: index, limit: 15, orderBy: 'date:desc' }
      if (this.journeyFilter) params[this.journeyFilter] = true
      this.disabled = true
      return journeys(this.user, params)
        .then(res => {
          this.pagination = res.meta.pagination
          this.items = this.items.concat(res.data)
          this.$store.dispatch('entities/journeys/disableFilters', false)
        })
        .then(() => {
          this.disabled = false
        })
    }
  }
}
</script>

<style lang="stylus" scoped>
.layout-padding
  max-width 1024px
.text-uppercase
  text-transform: text-uppercase
.mobile-header
  height: 30px
  padding: 0 5px
  font-size: 18px
  margin-bottom: 18px
.bookings
  font-size: 13px;
  letter-spacing: 1.5px;
  color: rgb(25, 118, 210)
.q-tab
  width 50%
</style>
