<template>
  <div class="row items-center justify-center border-0">
    <div class="col-12 col-md-8">
      <timeline :journey="journey" />
    </div>
    <div class="q-mt-md col-12 col-xl-2">
      <div class="nearby">
        <div class="text-center">
          <em>{{ $t('amenities_nearby') }}</em>
        </div>
        <div class="three-grid">
          <div v-for="(item, key) in nearby" :key="key" class="text-center">
            <q-btn
              unelevated round
              :icon="`fas fa-${item.icon.name}`"
              text-color="primary"
              @click="exploreNearby(item)"
            />
            <div class="text-subtitle2">
              {{ item.label }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
import timeline from './timeline.vue'
import { mapGetters } from 'vuex'
import http from 'api/http'
import travelContents from 'mixins/travelContents'
import dateTime from 'utils/date-time'
export default {
  name: 'JourneyTimeline',
  components: { timeline },
  filters: {
    date (val) {
      return dateTime.toHugeDate(val)
    },
    dateShort (val) {
      return dateTime.toShortDate(val)
    },
    time (val) {
      return dateTime.toCivilTime(val)
    }
  },
  mixins: [travelContents],
  props: ['journey'],
  computed: {
    ...mapGetters({
      authPartner: 'partner'
    }),
    nearby () {
      return this.authPartner.nearby
    }
  },
  methods: {
    exploreNearby (item) {
      http.get(`/journeys/${this.journey.reference}/discovery/${item.type}`)
        .then(response => {
          this.$router.push({
            name: 'home-search',
            params: {
              type: item.type,
              label: item.label,
              icon: item.icon,
              color: item.color.marker,
              results: response.data.map(res => {
                return {
                  description: res.vicinity,
                  name: res.name,
                  type: item.type,
                  place_id: res.place_id,
                  latitude: res.geometry.location.lat,
                  longitude: res.geometry.location.lng,
                  attributes: {
                    icon: res.icon,
                    opening_hours: res.opening_hours,
                    photos: res.photos,
                    rating: res.rating,
                    reference: res.reference,
                    types: res.types
                  }
                }
              })
            }
          })
        })
    }
  }
}
</script>

<style lang="stylus" scoped>
.nearby
  z-index 10
  padding 16px
  border-radius 16px
  padding-top 0px
  display grid
  grid-auto-rows auto
  grid-row-gap 24px
  align-items center

.three-grid
  display grid
  grid-template-columns repeat(3, 1fr)
  grid-row-gap 16px

em
  color convert(brandColor)
</style>
