<template>
  <q-card class="relative-position cursor-pointer shadow-transition hoverable">
    <m-image
      :src="cityImg"
      width="100%"
      height="120px"
    />

    <m-icons-type
      :type="type"
      size="56px"
      rounded
      class="absolute"
    />
    <q-card-section
      class="bg-white"
      style="padding-top: 14px; margin-top: 28px"
    >
      <q-item-label label class="ellipsis">
        {{ name ? name : $t('no_name_for_journey') }}
      </q-item-label>
      <q-item-label caption>
        {{ date | format }}
      </q-item-label>
    </q-card-section>
  </q-card>
</template>

<script type="text/javascript">
import travelContents from 'mixins/travelContents'
import date from 'utils/date-time'
export default {
  filters: {
    format (value) {
      return date.toHugeDate(value)
    }
  },
  mixins: [travelContents],
  props: {
    name: {
      type: String,
      default: 'Journey name'
    },
    info: {
      type: String,
      default: 'Journey info'
    },
    city: {
      type: String,
      default () {
        let cities = ['birmingham', 'brighton', 'cardiff', 'dublin', 'edinburgh', 'leeds', 'liverpool', 'london', 'manchester', 'newcastle']
        return cities[Math.floor(Math.random() * cities.length)]
      }
    },
    label: {
      type: String,
      default: 'My next journey'
    },
    date: {
      type: String,
      default: () => new Date()
    },
    type: {
      type: String,
      default: 'Journey type'
    },
    links: {
      type: [Object, Array],
      default: () => ({
        'background': null
      })
    }
  },
  computed: {
    cityImg () {
      return this.links.background || require(`assets/${this.getTravelContentBackground(this.type)}`)
    }
  }
}
</script>

<style lang="stylus" scoped>
.q-card-section
  height 120px
.q-card-section
  font-size 16px
  text-overflow ellipsis
  white-space nowrap
  overflow hidden
.q-card-section-overlay
  background-color transparent
  .q-card-subtitle
    color rgba(0,0,0,0.6)
.col.column
  width 8%
.icon-type
  left 8px
  top 80px
</style>
