<template>
  <div class="bookings-container">
    <div class="bookings">
      <m-index-list
        :load="load"
        :filters="filters"
        :sort="sort"
        :columns="columns"
        :items="items"
        :reset-items="() => {$store.dispatch('entities/journeys/deleteAll')}"
        :item-height="62"
        height="calc(100vh - 189px)"
      >
        <template v-slot:filters>
          <filters v-model="filters" />
        </template>
        <template v-slot:item="{item}">
          <journey-card :item="item" />
        </template>
      </m-index-list>
    </div>
  </div>
</template>

<script>
import { mapValues, pickBy } from 'lodash'
import mIndexList from '@/MIndexList'
import Journey from 'store/models/journey'
import filters from './filters'
import journeyCard from './journey-card'
import authentication from 'mixins/authentication'
import { mapGetters } from 'vuex'

export default {
  name: 'BackofficeJourneys',
  components: {
    filters,
    mIndexList,
    journeyCard
  },
  mixins: [authentication],
  data () {
    return {
      filters: {
        hasContentType: null,
        user: null,
        organisation: null,
        reference: '',
        startsBetween: null,
        labels: null
      },
      sort: {
        column: 'date',
        direction: 'desc'
      },
      columns: [
        {
          label: 'Reference',
          value: 'reference',
          sortable: true
        },
        {
          label: 'Name',
          value: 'name',
          sortable: true
        },
        {
          label: 'Date',
          value: 'date',
          sortable: true
        },
        {
          label: 'Traveller'
        },
        {
          label: 'Organisation'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      consumerToken: 'partner/consumerToken'
    }),
    items () {
      return Journey.query()
        .orderBy(this.sort.column, this.sort.direction)
        .get()
    }
  },
  watch: {
    consumerToken: {
      deep: true,
      handler () {
        const filters = {
          ...this.filters,
          page: 1,
          limit: 20,
          orderBy: 'created_at:desc'
        }
        this.$store.dispatch('entities/journeys/deleteAll')
        this.load(pickBy(filters))
      }
    }
  },
  created () {
    if (!this.hasPermission('can.book.for.anyone')) {
      this.columns.splice(4, 1)
    }
    this.$store.dispatch('entities/labels/loadAllLabels')
  },
  methods: {
    async load (filters) {
      const filtersToSend = mapValues(filters, (f) => {
        /**
         * Awful hack to fix sorting by right API value
         */
        if (typeof f === 'string' && f.startsWith('name')) {
          return f.replace('name', 'meta->name')
        }

        return f.value ? f.value : f
      })

      return this.$store.dispatch('entities/journeys/loadAllJourneys', filtersToSend)
    }
  }
}
</script>
