<template>
  <q-card class="relative-position cursor-pointer hoverable row">
    <div class="col-2 flex align-center">
      <m-icons-type
        :type="type"
        size="32px"
        inverted
      />
    </div>
    <div class="col flex column justify-around details">
      <q-item-label label class="ellipsis journey-name text-bold">
        {{ name ? name : $t('no_name_for_journey') }}
      </q-item-label>
      <q-item-label v-if="singleDay" caption class="date">
        {{ startDate | format }} <em>{{ journeyTime }}</em>
      </q-item-label>
      <q-item-label v-else caption class="multi-date">
        <div v-if="startDate" class="date">
          <em>{{ $t('from') }}</em> <span class="flex-full">{{ startDate | format }}</span> <em>{{ startDate | time }}</em>
        </div>
        <div v-if="endDate" class="date">
          <em>{{ $t('until') }}</em> <span class="flex-full">{{ endDate | format }}</span> <em>{{ endDate | time }}</em>
        </div>
      </q-item-label>
    </div>
  </q-card>
</template>

<script type="text/javascript">
import travelContents from 'mixins/travelContents'
import date from 'utils/date-time'
const { toHugeDate, toCivilDate, toCivilTime } = date
import { toLocalISO } from 'utils/timezoneFilter'
import { MIconsType } from 'components/'

export default {
  components: { MIconsType },
  filters: {
    format (value) {
      if (!value) return
      return toHugeDate(value)
    },
    time (value) {
      if (!value) return
      return toCivilTime(toLocalISO(value))
    }
  },
  mixins: [travelContents],
  props: {
    name: {
      type: String,
      default: 'Leeds journey'
    },
    info: {
      type: String,
      default: 'Flight Number #BA23131'
    },
    label: {
      type: String,
      default: 'My next journey'
    },
    startDate: {
      type: String,
      default: ''
    },
    endDate: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'hotel'
    },
    links: {
      type: [Object, Array],
      default: () => ({
        'background': null
      })
    }
  },
  computed: {
    singleDay () {
      return toCivilDate(this.startDate) === toCivilDate(this.endDate)
    },
    journeyTime () {
      if (!this.startDate || !this.endDate) return
      if (this.startDate === this.endDate) return toCivilTime(toLocalISO(this.startDate))
      const start = toCivilTime(toLocalISO(this.startDate))
      const end = toCivilTime(toLocalISO(this.endDate))

      return `${start}-${end}`
    }
  }
}
</script>

<style lang="stylus" scoped>
.details
  padding 8px
  padding-left 0
.q-card
  height 70px
  margin 0px
  border-radius 0
  .q-card-subtitle
    color rgba(0,0,0,0.6)
.col.column
  width 8%
.icon-type
  left 8px
  top 80px
.date
  display flex
  justify-content space-between
.multi-date
  display flex
  flex-direction column

em
  color convert(brandColor)
  margin-right 8px
  font-weight 500

.flex-full
  flex-basis 100%
</style>
