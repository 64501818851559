<template>
  <div class="layout-padding q-pa-md">
    <q-inner-loading v-if="!journey" :visible="!journey">
      <q-spinner-dots size="60" />
    </q-inner-loading>
    <q-timeline v-else class="q-ma-none q-pa-none" :layout="$q.screen.lt.sm ? 'dense' : 'comfortable'">
      <q-timeline-entry heading>
        <div class="text-h6">
          {{ journey.name }}
        </div>
        <div v-if="multiDay" class="text-caption">
          {{ journey.start_date.date | dayShort }} - {{ journey.end_date.date | dayShortDetailed }}
        </div>
        <div v-else class="text-caption">
          {{ journey.start_date.date | dayShortDetailed }}
        </div>
      </q-timeline-entry>

      <span
        v-for="(item, index) in items"
        :key="item.reference"
      >
        <q-timeline-entry v-if="item.type !== 'spacer'" :side="(index % 2) ? 'left' : 'right'" color="primary" class="q-ma-none q-pa-none" :subtitle="item.starts_at.date | timelineDate">
          <div class="row q-pa-none q-ma-none">
            <div class="col text-left column justify-center">
              <q-icon :color="getTravelContentColor(item.booking.type)" :name="getTravelContentIcon(item.booking.type)" size="28px" />
            </div>
            <div class="col-11">
              <q-card class="bookingCard">
                <q-card-section>
                  <div class="row justify-between">
                    <div class=" text-bold col text-subtitle2" :class="`text-${getTravelContentColor(item.booking.type)}`">
                      {{ $tc(`content_type.${item.booking.type}`) }}
                    </div>
                    <div class="col text-right text-caption">
                      <span class="text-bold">{{ duration(item.starts_at.date, item.ends_at.date) | duration }}</span>
                      <div class="text-caption">
                        {{ item.booking.reference }}
                      </div>
                    </div>
                  </div>
                </q-card-section>
                <q-card-actions align="right">
                  <q-btn
                    v-if="['gohi'].includes(partner.slug) && ticketLink(item)"
                    dense
                    :label="$t('view_tickets')"
                    no-caps
                    color="primary"
                    @click="$router.push(ticketLink(item))"
                  />
                  <q-btn
                    dense
                    :label="$t('amend')"
                    no-caps
                    outline
                    color="primary"
                    @click="$router.push(`/bookings/${item.booking.reference}?type=amendment&amp;booking=${item.booking.reference}#/concierge`)"
                  />
                  <q-btn
                    dense
                    :label="$t('cancel')"
                    no-caps
                    outline
                    color="primary"
                    @click="$store.dispatch('entities/bookings/cancel', item.booking.reference)"
                  />
                  <q-btn
                    dense
                    :label="$t('details')"
                    no-caps
                    outline
                    color="primary"
                    @click="$router.push(`/bookings/${item.booking.reference}`)"
                  />
                </q-card-actions>
              </q-card>
            </div>
          </div>
        </q-timeline-entry>
        <q-timeline-entry v-if="item.type !== 'spacer'" body="test" class="empty" :side="(index % 2) ? 'left' : 'right'" color="primary" :subtitle="item.ends_at.date | timelineDate" />
        <q-timeline-entry v-else class="spacer" :side="(index % 2) ? 'left' : 'right'">
          <div class="row q-pa-none q-ma-none">
            <div class="col text-left column justify-center">
              <q-icon name="far fa-history" size="24px" />
            </div>
            <div class="col-11">
              <q-card class="bookingCard">
                <q-card-section>
                  <div class="row justify-between">
                    <div v-if="item.duration.days" class="col text-subtitle2">
                      {{ item.duration.days }} days, {{ item.duration.hours }} hours & {{ item.duration.minutes }} minutes
                    </div>
                    <div v-else-if="item.duration.hours" class="col text-subtitle2">
                      {{ item.duration.hours }} hours & {{ item.duration.minutes }} minutes
                    </div>
                    <div v-else-if="item.duration.minutes" class="col text-subtitle2">
                      {{ item.duration.minutes }} minutes
                    </div>
                  </div>
                </q-card-section>
              </q-card>
            </div>
          </div>
        </q-timeline-entry>
      </span>
    </q-timeline>
  </div>
</template>

<script type="text/javascript">
import { mapGetters } from 'vuex'
import travelContents from 'mixins/travelContents'
import dateTime from 'utils/date-time'
export default {
  name: 'JourneyTimeline',
  filters: {
    date (val) {
      return dateTime.toHugeDate(val)
    },
    dateShort (val) {
      return dateTime.toShortDate(val)
    },
    timelineDate (val) {
      return dateTime.luxonFormatDate(val, 'dd/MM/yy, HH:mm')
    },
    dayShort (val) {
      return dateTime.luxonFormatDate(val, 'ccc, dd')
    },
    dayShortDetailed (val) {
      return dateTime.luxonFormatDate(val, 'ccc, dd LLLL y')
    },
    duration (val) {
      return `${val.hours}h ${Math.ceil(val.minutes)}m`
    },
    time (val) {
      return dateTime.toCivilTime(val)
    }
  },
  mixins: [travelContents],
  props: ['journey'],
  computed: {
    ...mapGetters({
      partner: 'partner'
    }),
    items () {
      let addSpacer = []
      let items = this.journey.items.data.filter(item => item.type === 'booking')
      items.forEach((item, index) => {
        const nextItem = items[index + 1]
        if (!nextItem) return

        if ((item.ends_at.date) < (nextItem.starts_at.date)) {
          const dateDiff = this.duration(item.ends_at.date, nextItem.starts_at.date, ['days', 'hours', 'minutes'])
          addSpacer.push({ index: index + 1, duration: dateDiff })
        }
      })

      addSpacer.sort((a, b) => b.index - a.index)
      addSpacer.forEach(spacer => {
        items.splice(spacer.index, 0, { type: 'spacer', duration: spacer.duration })
      })

      return items
    },
    multiDay () {
      let e = {
        start: dateTime.newDate(this.journey.start_date.date).startOf('day'),
        end: dateTime.newDate(this.journey.end_date.date).startOf('day')
      }
      return e.end > e.start
    }
  },
  methods: {
    duration (date1, date2, units) {
      let duration = dateTime.getDateDiff(date2, date1, units || ['hours', 'minutes'])
      return duration.toObject()
    },
    ticketLink (item) {
      const ticketable = ['rail', 'bus']
      if (!ticketable.includes(item.booking.type)) return false

      switch (item.booking.type) {
      case 'bus':
        return '/tickets'
      case 'rail':
        return `/bookings/${item.booking.reference}/documents`
      }
    }
  }
}
</script>

<style lang="stylus">
.q-inner-loading
  background: transparent
  height: 200px
.layout-padding
  max-width 768px
.bookingCard
  background-color darken(white, 3.5)
  box-shadow none
  padding 0
.q-card__section
  padding 8px
.q-timeline__content, .q-timeline__subtitle
  padding 0
  margin 0
.empty .q-timeline__content, .q-timeline__title, .spacer .q-timeline__dot
  display none
.q-timeline__heading-title
  padding-bottom 16px
</style>
