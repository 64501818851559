<template>
  <div v-if="journey">
    <router-view :journey="journey" />
  </div>
  <div v-else-if="loading">
    <m-spinner-geo />
  </div>
  <div v-else>
    {{ $t('error.retrieving_journey') }}
  </div>
</template>

<script type="text/javascript">
import { get as getJourney } from 'api/journeys'
import travelContents from 'mixins/travelContents'
import authentication from 'mixins/authentication'
export default {
  name: 'JourneysShow',
  mixins: [travelContents, authentication],
  data () {
    return {
      loading: true,
      journey: null
    }
  },
  created () {
    this.loading = true
    getJourney(this.$route.params.reference, { include: ['bookings'] })
      .then(res => {
        this.journey = res.data
      })
      .catch(err => {
        this.loading = false
        this.$q.notify({
          message: this.$t('error.default'),
          caption: err.data.message,
          type: 'negative'
        })
      })
      .finally(() => {
        this.loading = false
      })
  }
}
</script>

<style lang="stylus" scoped>

</style>
